

























import {Component, Vue} from "vue-property-decorator";
import QuestListItem from "@/components/QuestListItem.vue";
import QuestReward from "@/components/QuestReward.vue";

@Component({
  name: 'AchievementsResponsive',
  components:{ QuestListItem, QuestReward}
})
export default class AchievementsResponsive extends Vue {
  protected sidebarOpen = false;
  protected notificationOpen = false;
  protected dropdownOpen = false;
}

