




































































































import {Component, Prop, Vue} from "vue-property-decorator";
import {IndexDBClient} from "@/api/indexeddb/IndexDBClient";
import QuestDetailsResponsive from "@/views/QuestDetailsResponsive.vue";

@Component({
  name: "NewCardResponsive",
  components:{QuestDetailsResponsive}
})
export default class NewCardResponsive extends Vue {

  private isOpen!: boolean;
  private isClosable!: boolean;

  private cardId: string;
  private title!: string;
  private type!: string;
  private description!: string;
  private status!: string;
  private reward!: string;

  private cardTypes = [];
  private statusTypes = [];
  private cardType!: any;
  private statusType!: any;
  private database = new IndexDBClient();
  private cardSequenceId = [];

  @Prop({default: {id: 1}})
  protected selectedBoard?: any;

  constructor() {
    super();
    this.isOpen = true;
    this.isClosable = false;
    this.cardId = this.getGUID("bvg");
    this.title = "";
    this.type = "";
    this.status = "";
    this.description = "";
    this.reward = "1";
    this.cardType = null;
    this.statusType = {id: 0, name: "Not Assigned", icon: "exclamation-circle"};
    this.cardSequenceId = [];

    console.log(this.selectedBoard);

    /*this.database.types.then( (types: any) => {
      console.log(types);
    })*/
  }

  async saveCard() {
    const nextId = await Promise.resolve(this.database.cardsSequence);
    console.log(this.selectedBoard);

    console.log(this.cardSequenceId)
    const cardToUpdate = {
      boardId: this.selectedBoard.id,
      cardId: nextId.length + 1,
      title: this.title,
      type: this.cardType,
      status: this.statusType,
      description: this.description,
      dateLastActivity: new Date()
    };

    this.database.cardsSequence = {id: nextId.length + 1};

    console.log(cardToUpdate);

    this.database.cards = cardToUpdate;

    this.database.cardHistory = {
      cardId: cardToUpdate.cardId,
      items: [{
        id: this.getGUID(cardToUpdate.cardId),
        date: new Date(),
        card: cardToUpdate
      }]
    };

    this.cardId = "";
    this.title = "";
    this.type = "";
    this.status = "";
    this.description = "";
    this.reward = "";
    this.cardType = null;
    this.statusType = {id: 0, name: "Not Assigned", icon: "exclamation-circle"};
    this.$emit("asideMenuClose");
  }

  get categories() {
    this.database.cardTypes.then((types: never) => {
      this.cardTypes = types;
    })
    return this.cardTypes;
  }

  get stages() {
    this.database.cardStatus.then((status: never) => {
      this.statusTypes = status;
    })
    return this.statusTypes;
  }

  get nextCardSequence(): any{
    this.database.cardsSequence.then((cardSequence: any) => {
      console.log(cardSequence);
      this.cardSequenceId = cardSequence;
    })
    return this.cardSequenceId;
  }

  getIcon(icon){
    if(icon == undefined)
      return ['fa','exclamation-circle']
    return ['fa', icon];
  }

  getGUID = (function() {
    let cntr = 0;
    return function(userID?) {
      const rand = Math.random().toString().replace(".", "");
      return userID + "_" + rand + "_" + cntr++;
    };
  })();

}

