










































































































































































































































































































































































































































































import {Component, Vue, Watch} from "vue-property-decorator";
import QuestListItem from "@/components/QuestListItem.vue";
import QuestReward from "@/components/QuestReward.vue";
import Card from "@/components/Card.vue";
import {IndexDBClient} from "@/api/indexeddb/IndexDBClient";
import QuestDetailsResponsive from "@/views/QuestDetailsResponsive.vue";
import CardDetails from "@/components/CardDetails.vue";
import NewCardResponsive from "@/views/NewCardResponsive.vue";
import AchievementsResponsive from "@/views/AchievementsResponsive.vue";

@Component({
  name: 'ExploreResponsive',
  components:{AchievementsResponsive, QuestListItem, QuestReward, Card, QuestDetailsResponsive, CardDetails, NewCardResponsive}
})
export default class ExploreResponsive extends Vue {
  protected sidebarOpen = false;
  protected notificationOpen = false;
  protected settingsOpen = false;
  protected dropdownOpen = false;
  protected isOpen = false;
  protected isNewCard = false;

  protected type = {key: 1, name: 'Bounty', icon: 'bug'};
  protected status = {id: 0, name: 'Not Assigned', icon: 'exclamation-circle', acronym: 'FCR'};

  private numAllCards = 0;

  private unassignedCards: boolean;

  protected cards = [];
  protected card: any;

  private numberOfRecommendedCards: number;
  private numberOfBugCards: number;
  private numberOfTodoCards: number;
  private numberOfResearchCards: number;

  protected database = new IndexDBClient();

  protected selectedBoard: any;

  constructor() {
    super();
    this.unassignedCards = true;
    this.card = {status:{icon:'exclamation-circle', name:'Not Assigned'}};

    this.numberOfRecommendedCards = 0;
    this.numberOfBugCards = 0;
    this.numberOfTodoCards = 0;
    this.numberOfResearchCards = 0;
    this.selectedBoard = this.$store.state.selectedBoard;

    if(this.selectedBoard == ""){
      this.database.selectedBoard.then( (board: any) => {
        this.selectedBoard = board[0];
      });
    }
  }

  get allCards() {
    if(this.unassignedCards)
    {
      this.database.getUnassignedCards().then((cards: any) => {
        this.numAllCards = cards.length;
        this.cards = cards;
      })
    }
    else {
      this.database.cards.then((cards: any) => {
        this.numAllCards = cards.length;
        this.cards = cards;
      })
    }
    return this.cards;
  }

  showCard(card: any): boolean {
    const searchingText: any = this.$store.state.searching;
    if(searchingText !== "") {
      if(card.title.toLowerCase().indexOf(searchingText.toLowerCase()) != -1) {
        if(!this.$route.query.filter || this.$route.query.filter == "-1")
          return true;
        return card.type.key == this.$route.query.filter;
      }
      else{
        return false;
      }
    }
    else {
      if(!this.$route.query.filter || this.$route.query.filter == "-1")
        return true;
      return card.type.key == this.$route.query.filter;
    }
  }

  get unassignedCardsOnly() {
    return this.unassignedCards;
  }

  set unassignedCardsOnly(value) {
    this.unassignedCards = value;
  }

  openCloseMenu() {
    this.isOpen = !this.isOpen;
  }

  showCardDetails( card: any ){
    this.isOpen = true;
    this.card = card;
  }

  get getCard(): any {
    return this.card;
  }

  get bountyCards() {
    this.database.getBountyCards(this.unassignedCards, this.selectedBoard.id).then( (cards: any) => {
      this.numberOfBugCards = cards.length;
    })
    return this.numberOfBugCards;
  }

  get developmentCards() {
    this.database.getDevelopmentCards(this.unassignedCards, this.selectedBoard.id).then( (cards: any) => {
      this.numberOfTodoCards = cards.length;
    })
    return this.numberOfTodoCards;
  }

  get recommendedCards() {
    /*console.log(this.selectedBoard);*/
    this.database.getRecommendedCards(this.unassignedCards, this.selectedBoard.id).then( (cards: any) => {
      this.numberOfRecommendedCards = cards.length
    })
    return this.numberOfRecommendedCards;
  }

  get investigationCards() {
    this.database.getInvestigationCards(this.unassignedCards, this.selectedBoard.id).then( (cards: any) => {
      this.numberOfResearchCards = cards.length;
    })
    return this.numberOfResearchCards;
  }

@Watch('unassignedCards')
  updateCall(value){
    this.unassignedCards = value;
  }

  addNewCard(){
    this.isNewCard = true;
  }

  openCloseNewCard() {
    this.isNewCard = !this.isNewCard
  }

}

