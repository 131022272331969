import { render, staticRenderFns } from "./NewCardResponsive.vue?vue&type=template&id=6c90d4c4&scoped=true&"
import script from "./NewCardResponsive.vue?vue&type=script&lang=ts&"
export * from "./NewCardResponsive.vue?vue&type=script&lang=ts&"
import style0 from "vue-multiselect/dist/vue-multiselect.min.css?vue&type=style&index=0&lang=css&"
import style1 from "./NewCardResponsive.vue?vue&type=style&index=1&id=6c90d4c4&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c90d4c4",
  null
  
)

export default component.exports